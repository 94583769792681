body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical-timeline-element-icon {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.design-plan-timeline.vertical-timeline::before {
  top: 50px !important;
  height: 80% !important;
  width: 3px !important;
}

.cto-service-timeline.vertical-timeline::before {
  top: 50px !important;
  height: 75% !important;
  width: 3px !important;
}